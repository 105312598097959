import { cloneDeep, reduce } from 'lodash';
import { DeliveryMethod, DiscountType, OrderStatus, PointCurrency } from 'Constants/enums';
import { formatReward } from 'Utils/currency';
import { subtractMoney } from "Utils/money";

export const getMerchantsObject = ({ invoiceInfo, products }) => reduce(
  products,
  (acc, product) => {
    const { merchant } = product;
    // Increments number of items
    if (acc[merchant.id]) {
      return {
        ...acc,
        [merchant.id]: {
          ...acc[merchant.id],
          products: [...acc[merchant.id].products, product],
        },
      };
    }
    // Adds new Merchant
    return {
      ...acc,
      [merchant.id]: {
        ...merchant,
        cardCharge: invoiceInfo.cardCharges[merchant.id],
        products: [product],
      },
    };
  },
  {},
);

export const getProductsAsList = (products) => (
  reduce(products, (acc, product, key) => {
    const productKeys = Object.keys(products);
    const productsLength = productKeys.length;

    const shouldHaveComma = key !== productKeys[productsLength - 1];

    return `${acc}${product.name}${shouldHaveComma ? ', ' : ''}`;
  }, '')
);

export const convertBountyToFirebaseOrder = (bounty) => ({
  bounty: {
    shortId: bounty?.shortId,
    order: bounty?.order,
    id: bounty?.id,
    createdAt: bounty?.createdAt,
  },
  productsInfo: bounty?.order?.productsInfo,
  status: bounty?.order?.status,
  orderTotal: bounty?.order?.orderTotal,
  creator: bounty?.creator,
  owner: bounty?.owner,
});

export const canCompleteOrCancelOrder = (order) => (
  ![OrderStatus.Cancelled, OrderStatus.Declined, OrderStatus.Completed].includes(order.status)
);

export const isPickupMethod = (method) => (
  [DeliveryMethod.Pickup, DeliveryMethod.Curbside].includes(method)
);

export function getDiscountsTotal(discounts) {
  const formattedDiscounts = {
    [DiscountType.Delivery]: {
      amount: 0,
      currency: '',
    },
    [DiscountType.PromoCode]: {
      amount: 0,
      currency: '',
    }
  };

  discounts?.forEach(({ type, amount, redemptionAmount }) => {
    const { money } = amount || {};

    if (formattedDiscounts[type]) {
      formattedDiscounts[type].amount += money?.amount ? +money?.amount : 0;
      formattedDiscounts[type].currency = money?.currency;
    }
  });

  return formattedDiscounts;
}

export function getTaxesTotal(taxes) {
  let taxesTotal = 0;
  let taxesCurrency = '';

  taxes?.forEach((tax) => {
    const amount = tax?.amount?.amount || 0;
    taxesTotal += +amount;
    taxesCurrency = tax?.amount?.currency;
  });

  return {
    amount: taxesTotal ? taxesTotal?.toString() : null,
    currency: taxesCurrency
  };
}

export const getOrderPriceDetails = ({ priceSummary, requiresSubtraction = false }) => {
  const {
    total,
    taxes,
    subTotal,
    discounts,
    roundingAmount,
    itemsTotal,
    deliveryFee,
    totalRedeemed,
    totalExchanged,
    merchantTotal,
  } = priceSummary || {};
  const taxesReward = taxes ? getTaxesTotal(taxes) : {};
  const {
    [DiscountType.PromoCode]: discountsReward,
    [DiscountType.Delivery]: deliveryReward,
  } = discounts ? getDiscountsTotal(discounts) : {};
  const totalWithoutSTKN = cloneDeep(total);
  delete totalWithoutSTKN?.points?.[PointCurrency.SystemToken];

  return {
    total: total?.money?.amount,
    subTotal: subTotal
      ? formatReward(subTotal, '0', true)
      : '',
    itemsTotal: itemsTotal
      ? formatReward(itemsTotal, '0', true)
      : '',
    formattedTotal: formatReward(total, '0', true),
    formattedTotalWithoutSTKN: formatReward(totalWithoutSTKN, '0', true),
    formattedMerchantTotal: formatReward(merchantTotal, '0', true),
    taxes: taxesReward?.amount
      ? formatReward({ money: taxesReward }, '0', true)
      : '',
    deliveryFee: deliveryFee
      ? formatReward(deliveryFee, '0', true)
      : '',
    roundingAmount: roundingAmount?.money?.amount?.includes('0.00')
      ? ''
      : formatReward(roundingAmount, '', true),
    deliveryDiscount: deliveryReward?.amount
      ? formatReward({ money: deliveryReward }, '', true)
      : '',
    discounts: discountsReward?.amount
      ? `${discountsReward?.amount > 0 ? '-' : ''}${formatReward({ money: discountsReward }, '0', true,)}`
      : undefined,
    redemptionMoney: formatReward(totalExchanged, '', true),
    redemptionPoints: formatReward(totalRedeemed, '', true),
  };
};
